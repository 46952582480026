
import Vue from "vue";

interface Computed {
  style: object;
}

interface Props {
  height: string;
  isActive: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    height: {
      type: String,
      default: "auto",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const { height } = this;
      return {
        height,
      };
    },
  },
});
