
import Vue from "vue";
import draggable from "vuedraggable";

interface Data {
  currentItems: unknown[];
}

interface Methods {
  onUpdate: (event: Event) => void;
}

interface Computed {
  model: unknown[];
}

interface Props {
  items: unknown[];
  handleClassName: string;
  keyAttr: string;
  disabled: boolean;
  extra: boolean;
  onReorder: (items: unknown[]) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { draggable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    handleClassName: {
      type: String,
      default: ".handle",
    },
    keyAttr: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extra: {
      required: false,
    },
    onReorder: Function,
  },
  data() {
    return {
      currentItems: this.items,
    };
  },
  watch: {
    // NOTE: 未取得のデータが先に入る可能性があるので監視しておく
    items() {
      this.currentItems = this.items;
    },
  },
  methods: {
    onUpdate(_: Event) {
      this.$emit("update:items", this.currentItems);

      if (this.onReorder) {
        this.onReorder(this.currentItems);
      }
    },
  },
});
