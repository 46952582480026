
/* eslint-disable no-param-reassign */
import { ListType, ListState } from "@/lib/models";
import PlaylistEditIcon from "icons/PlaylistEdit.vue";
import DeleteIcon from "icons/Delete.vue";
import Vue from "vue";

interface Methods {
  enter: (el: HTMLElement) => void;
  leave: (el: HTMLElement) => void;
  afterEnter: (el: HTMLElement) => void;
  afterLeave: (el: HTMLElement) => void;
  nextFrame: (fn: any) => void;
  onFolderIconClick: () => void;
  onEditIconClick: () => void;
  onDeleteIconClick: () => void;
  onCancelClick: () => void;
}

interface Computed {
  isEditing: boolean;
}

interface Props {
  title: string;
  isEditing: boolean;
  showFolderIcon: boolean;
  updateListState: (value: ListState) => void;
  updateListType: (type: ListType) => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    PlaylistEditIcon,
    DeleteIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    showFolderIcon: {
      type: Boolean,
      default: true,
    },
    updateListState: Function,
    updateListType: Function,
  },
  methods: {
    enter(el) {
      el.style.overflow = "hidden";
      el.style.width = "0";

      this.nextFrame(() => {
        el.style.width = `${el.scrollWidth}px`;
      });
    },
    leave(el) {
      el.style.overflow = "hidden";
      el.style.width = `${el.scrollWidth}px`;

      this.nextFrame(() => {
        el.style.width = "0";
      });
    },
    afterEnter(el) {
      el.style.width = "";
      el.style.overflow = "";
    },
    afterLeave(el) {
      el.style.width = "";
      el.style.overflow = "";
    },
    nextFrame(fn) {
      // NOTE: 以下参考にした
      // https://note.com/noliaki/n/n6e3a60748c11
      window.requestAnimationFrame(() => window.requestAnimationFrame(fn));
    },
    onFolderIconClick() {
      const { updateListType } = this;
      if (!updateListType) return;
      updateListType(ListType.FOLDER);
    },
    onEditIconClick() {
      const { updateListState } = this;
      if (!updateListState) return;
      updateListState(ListState.EDIT);
    },
    onDeleteIconClick() {
      const { updateListState } = this;
      if (!updateListState) return;
      updateListState(ListState.DELETE);
    },
    onCancelClick() {
      const { updateListState } = this;
      updateListState(ListState.COMPLETE);
    },
  },
});
